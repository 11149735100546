import { Box, Grid, Paper, Typography } from "@mui/material";
import { LoginFormComponent } from "../components/LoginForm";
import { Logo_Sicar_Icon } from "@sicarx/sxwa-core"

export const LoginView = (() => {
	return (
		<Box sx={{
			pr: 1, pl: 1,
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: '#f4f4f4'
		}}>
			<Paper sx={{ pt: 3, pb: 3, width: 550 }}>
				<Grid container direction="column" alignItems="center">
					<img src={Logo_Sicar_Icon} alt="logo" height="90" />
					<Typography
						sx={{ fontWeight: '400', mt: '1rem', textAlign: 'center', color: '#0A5DA6', fontSize: '18px !important' }}
						variant="h1" component="h1">
						ADMIN X
					</Typography>
				</Grid>

				<LoginFormComponent />

				<Grid item xs={9} sx={{ mt: '3rem', textAlign: 'center', mx: 'auto' }}>
					<Typography sx={{ color: '#424242', fontWeight: 'bold', mb: '6px', fontSize: '17px !important' }}>
						SICAR Solutions SA de CV
					</Typography>
					<Typography sx={{ color: '#424242', }} variant="body2">
						Hecho con Amor | Todos los derechos reservados
					</Typography>
				</Grid>
			</Paper>
		</Box>
	)
})