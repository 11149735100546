
import { useSnack } from '@sicarx/sxwa-core';
import { useFormik } from 'formik';
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { cipherPassword, getLoginSchema } from "@sicarx/sxwa-core";
import {
	RootAuthAccountState, RootAuthEnterpriseState, RootLoaderState,
	useAuthAccountStore, useAuthEnterpriseStore, useLoaderStore
} from "@sicarx/sxwa-api";

import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

export const LoginFormComponent = () => {

	const router = useNavigate();

	const { error } = useSnack();

	const [values, setValues] = useState({ showPassword: false });

	const showLoader = useLoaderStore((loaderStore: RootLoaderState) => loaderStore.showLoader)
	const authAccount = useAuthAccountStore((authStore: RootAuthAccountState) => authStore.authAccount)
	const loginAccount = useAuthAccountStore((authStore: RootAuthAccountState) => authStore.loginAccount)
	const setAuthAccount = useAuthAccountStore((authStore: RootAuthAccountState) => authStore.setAuthAccount)
	const authEnterprise = useAuthEnterpriseStore((enterpriseStore: RootAuthEnterpriseState) => enterpriseStore.authEnterprise)
	const loginEnterprise = useAuthAccountStore((authStore: RootAuthAccountState) => authStore.loginEnterprise)
	const setAuthEnterprise = useAuthEnterpriseStore((enterpriseStore: RootAuthEnterpriseState) => enterpriseStore.setAuthEnterprise)

	useEffect(() => {
		if (authAccount.logged && authEnterprise.jwt !== "")
			router("/dashboard", { replace: true });
	}, [authEnterprise.jwt]);

	const formik = useFormik({
		initialValues: {
			emailLogin: '',
			passwordLogin: ''
		},
		validationSchema: getLoginSchema(),
		onSubmit: async formData => {
			const password = cipherPassword(formData.passwordLogin);

			try {
				showLoader(true);
				const response: any = await loginAccount(formData.emailLogin, password,
					`${navigator.userAgent.trim()}`.substring(0, 127).trim(), "navigator", 'web');

				if (response.isLogged) {
					try {
						loginEnterprise(response.data.jwt).then((response2: any) => {

							if (response2.status === 200) {
								setAuthAccount(response.data)
								setAuthEnterprise(response2.data)
							}
						}).catch(() => {
							showLoader(false)
							formik.setSubmitting(false);
						})
					}
					catch (e) {
						showLoader(false)
						formik.setSubmitting(false);
					}
				}
				else
					error("No autorizado");

				showLoader(false)
				formik.setSubmitting(false);
			}
			catch (e) { }
			finally {
				showLoader(false)
				formik.setSubmitting(false);
			}
		}
	});

	const _handleClickShowPassword = () => setValues({ ...values, showPassword: !values.showPassword });

	return (
		<form id="login" onSubmit={formik.handleSubmit}>
			<Grid container>
				<Grid item xs={9} sx={{ mt: '3rem', mx: 'auto' }}>
					<TextField
						autoFocus
						fullWidth
						name="emailLogin"
						label="Usuario"
						variant="standard"
						color="primary"
						disabled={formik.isSubmitting}
						error={formik.touched.emailLogin && Boolean(formik.errors.emailLogin)}
						helperText={formik.touched.emailLogin && formik.errors.emailLogin}
						onChange={formik.handleChange}
						value={formik.values.emailLogin} />
				</Grid>

				<Grid item xs={9} sx={{ mt: '1.5rem', mx: 'auto' }}>
					<FormControl
						disabled={formik.isSubmitting}
						error={formik.touched.passwordLogin && Boolean(formik.errors.passwordLogin)}
						variant="standard"
						fullWidth
					>
						<InputLabel htmlFor="adornment-password">
							Contraseña
						</InputLabel>

						<Input
							id="adornment-password"
							name="passwordLogin"
							type={values.showPassword ? 'text' : 'password'}
							error={formik.touched.passwordLogin && Boolean(formik.errors.passwordLogin)}
							onChange={formik.handleChange}
							inputProps={{
								autoComplete: 'new-password'
							}}
							endAdornment={
								<InputAdornment position="end" sx={{ mb: '10px !important' }}>
									<IconButton
										disabled={formik.isSubmitting}
										aria-label="toggle password visibility"
										onClick={_handleClickShowPassword}>

										{values.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>

						<FormHelperText error>{formik.touched.passwordLogin && formik.errors.passwordLogin}</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={9} sx={{ mt: '1rem', textAlign: 'center', mx: 'auto' }}>
					<Button type="submit"
						variant="contained"
						id="btnLogin"
						size="large"
						sx={{ padding: '14px 22px !important' }}
						disabled={formik.isSubmitting}
						fullWidth
						disableElevation>

						{formik.isSubmitting ? <CircularProgress color="inherit" size="20px" sx={{ marginRight: '10px' }} /> : ''}
						<Typography>INGRESAR</Typography>
					</Button>
				</Grid>
			</Grid>
		</form>
	)
}

export default LoginFormComponent;