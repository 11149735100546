import { IndexRoutes } from './routes';
import { AdminDrawer } from './components/Drawer';
import { SicarLoading } from './components/SicarLoading';
import { useState, useEffect } from 'react';
import { useDrawerStore, RootDrawerState } from '@sicarx/sxwa-api';

function App() {
    const [isMounted, setIsMounted] = useState<boolean>(false);

	const drawerOpen = useDrawerStore((store: RootDrawerState) => store.drawerOpen);
    const isDrawerOpen = useDrawerStore((store: RootDrawerState) => store.drawer.open);

    useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

    useEffect(() => {
		if (isDrawerOpen && isMounted)
			drawerOpen(false);
	}, [isMounted]);

    return (
        <>
            <AdminDrawer />
            <SicarLoading />
            <IndexRoutes />
        </>
    )
}

export default App
