import registerEaIcon from '../assets/images/register-ea.png';
import documentsIcon from '../assets/images/documents.png';
import pluginsIcon from '../assets/images/plugin.png';
import { ValidateModuleParams } from "@sicarx/sxwa-api"
export interface IModules {
	module: string,
	icon: any
	to: string
	permission?: ValidateModuleParams
}

export const modules: Array<IModules> = [
	{ module: "Registros EA", icon: registerEaIcon, to: "/registros-ea", permission: { modules: ["early_access"] } },
	{ module: "Catálogo", icon: documentsIcon, to: "/catalogue" },
	// { module: "Plugins", icon: pluginsIcon, to: "/plugins" },
	//{ module: "Historial", icon: documentsIcon, to: "/history", modulePermission: "" },
	{ module: "Cuentas SX", icon: documentsIcon, to: "/account-sx", permission: { modules: ["account_details"] } },
	{ module: "Suscripciones", icon: documentsIcon, to: "/subscriptions", permission: { modules: ["account_details"] } }
]