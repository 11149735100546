import { Box } from "@mui/material";
import { Header } from "@sicarx/sxw-shared-components";
import { RootDrawerState, useDrawerStore } from "@sicarx/sxwa-api";

import { Logo_Sicar_Icon } from "@sicarx/sxwa-core"

export const DashboardView = (() => {
	const drawerOpen = useDrawerStore((store: RootDrawerState) => store.drawerOpen);
	const handleOpenDrawer = () => drawerOpen(true);

	return (
		<>
			<Header>
				<Header.Menu onClick={handleOpenDrawer} />
			</Header>

			<Box sx={{
				height: '80%',
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				justifyContent: 'center'
			}}>
				<img src={Logo_Sicar_Icon} width="160px" />
			</Box>
		</>
	)
})