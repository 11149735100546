import { memo } from "react"
import { useLoaderStore } from "@sicarx/sxwa-api";
import { Backdrop, Stack } from '@mui/material';
import { Lottie, Loader_Animation } from "@sicarx/sxwa-core"

interface ISicarLoading {
    open: boolean;
    transparent: boolean
}

export const SicarLoading = () => {
    const loader = useLoaderStore((store) => store.loader)
    return loader?.open ? <Loader {...loader} /> : <></>
}

const Loader: React.FC<ISicarLoading> = memo(({ open, transparent }) => {
    return (
        <Backdrop open={open} invisible={transparent} sx={{
            color: "#fff",
            backgroundColor: "#ffffff30",
            filter: "drop-shadow(2px 2px 3px black)",
            zIndex: (theme) => theme.zIndex.drawer + 20000
        }}>
            <Stack gap={1} justifyContent="center" alignItems="center">
                <Lottie
                    id="sicar-loader"
                    animationData={Loader_Animation}
                    loop={true}
                />
            </Stack>
        </Backdrop>
    )
})