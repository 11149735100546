import { modules } from "../utils/modules";
import { Salir_Icon } from '@sicarx/sxwa-core';
import { useNavigate } from "react-router-dom";
import { useResetState } from '@sicarx/sxwa-api';
import { useEffect, useState } from "react";
import { Drawer, Box, List, ListItem, ListItemIcon, ListItemText, Typography, Grid } from '@mui/material';
import { useDrawerStore, RootDrawerState, useAuthAccountStore, RootAuthAccountState, usePermission } from '@sicarx/sxwa-api';

import "../assets/css/styles_drawer.css";

export const AdminDrawer = () => {
	const router = useNavigate();

	const { logoutResetState } = useResetState();

	const [isMounted, setIsMounted] = useState<boolean>(false);

	const open = useDrawerStore((store: RootDrawerState) => store.drawer.open);
	const drawerOpen = useDrawerStore((store: RootDrawerState) => store.drawerOpen);
	const authAccount = useAuthAccountStore((store: RootAuthAccountState) => store.authAccount);
	const { vaidateModule } = usePermission()

	const handleClose = () => drawerOpen(false);

	const handleToModule = (to: string) => {
		router(to, { replace: true })
		drawerOpen(false)
	}

	const handleLogout = async () => {
		drawerOpen(false);
		logoutResetState();

		setTimeout(() => {
			router("/", { replace: true })
		}, 100)
	}

	useEffect(() => {
		setIsMounted(true);
		return () => setIsMounted(false);
	}, []);

	return (
		<Drawer
			variant="temporary"
			onClose={handleClose}
			open={open && isMounted}
		>
			<Box sx={{
				width: ("300px"),
				height: "100%",
				overflow: "hidden",
				marginBottom: "64px"
			}}>
				<Box sx={{ padding: "60px 16px 0px" }}>
					<List>
						<ListItem sx={{ margin: "0", padding: "8px" }}>
							<Grid container>

								<Grid item xs sx={{ maxWidth: "192px !important", paddingLeft: "8px" }}>
									<Grid container direction="column" sx={{
										display: "flex",
										justifyContent: "center",
										height: "100%"
									}}>
										<Typography color="primary" sx={{
											display: "-webkit-box",
											WebkitLineClamp: "2",
											WebkitBoxOrient: "vertical",
											overflow: "hidden",
											maxWidth: "100%",
											fontSize: '17px'
										}}>
											{`${authAccount.firstName} ${authAccount.lastName}`}
										</Typography>

										<Typography sx={{
											fontSize: "14px", color: 'lightText1', fontWeight: "bold",
											display: "-webkit-box",
											WebkitLineClamp: "2",
											WebkitBoxOrient: "vertical",
											overflow: "hidden",
											maxWidth: "100%"
										}}>
											{authAccount.email}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</ListItem>
					</List>
				</Box>

				<List className='menu'>
					{modules.filter(e => vaidateModule(e.permission)).map((module) =>
						<ListItem
							key={`${module.module}`}
							onClick={() => handleToModule(module.to)}
							className={("/" + location?.pathname?.split('/')?.[1] == module.to ? " selected" : "item")}
						>
							<ListItemIcon sx={{ minWidth: "24px", ml: 1, mr: 1 }}>
								<img src={module.icon} style={{
									display: "inline-block",
									verticalAlign: "middle",
									height: "24px",
									width: "24px"
								}} />
							</ListItemIcon>

							<ListItemText sx={{ mt: '12px', color: 'primary.main' }} primary={module.module} />
						</ListItem>
					)}
				</List>
			</Box>

			<Box sx={{
				bottom: 0,
				width: "100%",
				overflowX: "hidden",
				position: "absolute"
			}}>
				<Box sx={{
					display: 'flex',
					padding: "8px 6px",
					alignItems: 'center'
				}}>

					<ListItem sx={{ textTransform: 'none', color: 'error.main' }} className="item" onClick={handleLogout}>
						<ListItemIcon sx={{ minWidth: "24px !important", ml: 1, mr: 1 }}>
							<img src={Salir_Icon} className="imgClose" alt="Exit" style={{ height: '24px !important' }} />
						</ListItemIcon>

						<ListItemText sx={{ color: 'error.main' }} primary="Cerrar Session" />
					</ListItem>
				</Box>
			</Box>
		</Drawer>
	)
}


