import { LoginView } from "../views/login";
import { AccountGuard } from "@sicarx/sxwa-api";
import { DashboardView } from "../views/dashboard";
import { SubscriptionRoutes } from "@sicarx/sxwa-subscription";
import { CatalogueRoutes } from "@sicarx/sxwa-catalogue"
import { Navigate, useRoutes } from "react-router-dom";

export const IndexRoutes = (() => {
    const IndexRoutes = useRoutes([
        {
            path: "/",
            element: <LoginView />
        },
        {
            path: "/dashboard",
            element: <AccountGuard><DashboardView /></AccountGuard>
        },
        ...SubscriptionRoutes,
        ...CatalogueRoutes,
        { path: '*', element: <Navigate to="/" replace /> }
    ]);
    return IndexRoutes;
});